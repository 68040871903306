<template>
  <b-container fluid>
    <b-row>
      <div class="col-lg-12">
        <div class="card card-block">
          <div class="card-header  text-center">
            <img style="width:100%;" src="img/logo-estudio-app.png" alt="">
            <b-jumbotron lead="Acceso no permitido" class="text-center"></b-jumbotron>
            <p class="card-body">
              Lo sentimos, con el correo que iniciaste sesión no tienes acceso a nivelAndo,
              recuerda utilizar el correo con el que te registró el Coordinador del programa upgrAdeedu/nivelAndo en tu
              Colegio.
            </p>
            <p class="card-body">
              Si crees que esto se trata de un error, puedes pedirle a tu  Coordinador que se comunique con el área de
              Desarrollo Profesional de nivelA a través del Coach asignado a tu Colegio.
            </p>
          </div>
        </div>
      </div>
    </b-row>

  </b-container>
</template>
<script>
export default {
  name: "NoPermitido",
  data() {
    return {
      email: "",
      avatar: "",
    };
  },
  mounted() {

  },
  methods: {
    logout() {

    },
  },
};
</script>
