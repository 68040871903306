<template>
  <b-container fluid>
    <b-modal
        v-model="showModalConfirmar"
        persistent
        title="Solicitar nueva contraseña"
        size="lg"
        centered
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">
            ¿ Estás seguro de solicitar nueva contraseña ?
          </b-col>
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">

          <b-button
              variant="primary"
              size="sm"
              class="float-right"
              @click="hideConfirmarPwd()">
            No
          </b-button>

          <b-button
              variant="success"
              size="sm"
              class="float-right mx-2"
              @click="solicitarNewPassword()">
            Sí
          </b-button>

        </div>
      </template>

    </b-modal>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="showWaitCargando" rounded="sm" >
          <template #overlay>
            <div class="text-center">
              <h5>
                Favor de esperar, cargando información...
              </h5>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12">
        <b-overlay :show="showWait" rounded="sm" >
          <template #overlay>
            <div class="text-center">
              <h5>
                Favor de esperar, generando nueva contraseña...
              </h5>
            </div>
          </template>
        </b-overlay>
      </b-col>
    </b-row>
    <b-row>
      <div class="col-lg-12">
        <div class="card card-block">
          <div class="card-header  text-center">
            <b-jumbotron lead="Bienvenido a Ayuda de EstudioAPP" class="text-center"></b-jumbotron>
            <p class="card-body">
              Identificamos que tu correo está registrado en el programa nivelAndo, recuerda que para ingresar a la
              plataforma del programa debes utilizar la cuenta que recibiste a través de un correo electrónico de
              bienvenida. Tu correo nivelAndo es: <span class="text-primary">{{this.$store.getters.usuario.email}}</span>
            </p>
            <p class="card-body">
              Si deseas recuperar tu contraseña, puedes hacer clic en este botón
            </p>
            <b-row>
              <b-col class="align-content-center">

              <b-button
                        variant="primary"
                        size="sm"
                        class="text-center mx-2"
                        @click="showConfirmarPwd()"

              >
                Solicitar nueva contraseña
              </b-button>
              </b-col>
            </b-row>

            <b-row>
              <p class="card-body">
                Después se reenviará tu cuenta de acceso al correo con el que iniciaste sesión en este portal <span class="text-primary">({{this.$store.getters.usuario.email}})</span> con una nueva contraseña para ingresar
              </p>
            </b-row>

            <b-row>
              <b-col cols="12">
              <div class="d-flex justify-content-between align-items-center">
                <b-col cols="6">
                  <b-button
                      variant="success"
                      size="sm"
                      @click="openVideo()"

                  >
                    Consultar el video introductorio
                  </b-button>
                </b-col>
                <b-col cols="6">
                  <b-button
                      variant="success"
                      size="sm"
                      @click="openManual()"

                  >
                    Consulta el manual de apoyo
                  </b-button>
                </b-col>
              </div>
              </b-col>
            </b-row>

          </div>
        </div>
      </div>
    </b-row>

  </b-container>
</template>
<script>
//import ResetEmail from '../components/ResetEmail';
import toast from "@/mixins/ToastMixin.js";

export default {
  name: "Permitido",
  mixins:[toast],
/*  components:{
    ResetEmail
  },*/
  data() {
    return {
      showModalConfirmar:false,
      showWait:false
      ,showWaitCargando:false
    };
  },
  mounted() {
    this.waitEsperar()
  },
  methods: {
    openVideo(){
      window.open('https://api-nivelando.estudiolab.app/videotutorial', '_blank');
    },
    openManual(){
      window.open('https://api-nivelando.estudiolab.app/guia', '_blank');
    },
     solicitarNewPassword(){
      this.hideConfirmarPwd()
      this.showWait=true
       const url = `/api/reset-password`;
       this.$api.post(url,  {
         email: this.$store.getters.usuario.email
       })
           .then((response) => {
              this.$router.push({
                name:'ResetEmail',
                params:{
                  email:response.data.email_nivelando,
                  password:response.data.password
                }
              })
           })
           .catch(() => {
             console.log("error generado")
           })
    },
    showConfirmarPwd(){
      this.showModalConfirmar=true
    },
    hideConfirmarPwd(){
      this.showModalConfirmar=false
    },
  },
};
</script>
