export default {
    methods: {
        toast(toaster, append = false,content,color)
        {
            this.counter++
            this.$bvToast.toast(content, {
                title: 'Nivelando',
                autoHideDelay: 3500,
                toaster: toaster,
                variant: color,
                appendToast: append
            });
        },
        wait(){
            this.$bvToast.toast('Procesando...Favor de esperar', {
                title: 'Ayuda',
                autoHideDelay: 4000,
                toaster: 'b-toaster-bottom-center',
                variant: 'info',
                appendToast: true,
                remaining:true
            });
        },
        waitEsperar(){
            this.$bvToast.toast('Procesando...Favor de esperar', {
                title: 'Ayuda',
                autoHideDelay: 12000,
                toaster: 'b-toaster-bottom-center',
                variant: 'info',
                appendToast: true,
                remaining:true
            });
        },
    },
}
