import Vue from 'vue'
import Vuex from 'vuex'
//import toast from "@/mixins/ToastMixin.js";
Vue.use(Vuex)

export default new Vuex.Store({
  //mixins:[toast],
  state: {
    loadingStatus: "notLoading",
    usuario: {},
    appName: 'Ayuda',
    logo: require('../assets/images/logo.png'),
    img: require('../assets/images/logo-dark.png'),
    dark: false,
    namespaced: true,
    user:{
      name:'Bill Yerds',
      image:require('../assets/images/user/1.jpg'),
    }
  },
  mutations: {
    SET_LOADING_STATUS(state, status) {
      state.loadingStatus = status;
    },
    SET_USUARIO(state, usuario) {
      state.usuario = usuario;
    },
    layoutModeCommit (state, payload) {
      state.dark = payload
      if (!payload) {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo-dark.png')
      } else {
        state.logo = require('../assets/images/logo.png')
        state.img = require('../assets/images/logo.png')
      }
    }
  },
  actions: {    
    fetchUsuario(context) {
      const routeParams  = window.location.search.substr(0);
      const currentRoute  = window.location.pathname;
      if (currentRoute === "/permitido") {
        let string = routeParams.split('?token=')
        Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
          Vue.prototype.$api.post('/api/validate-auth', {
            token: string[1]
          }).then(res => {
            if ( res.data.success )
            {
              Vue.prototype.$api.get("/api/userNivelando").then((response) => {
                this.commit("SET_USUARIO", response.data);
              });
            }
          })
        })
      }
      else {
        context.commit("SET_LOADING_STATUS", "loading");
        Vue.prototype.$api.get("/api/user").then((response) => {
          this.commit("SET_USUARIO", response.data);
        });
      }
  },
    layoutModeAction (context, payload) {
      context.commit('layoutModeCommit', payload.dark)
    }
  },
  getters: {
    usuario: (state) => {
      return state.usuario;
    },
    appName: state => { return state.appName },
    logo: state => { return state.logo },
    img: state => { return state.img },
    image1: state => { return state.user.image },
    name: state => { return state.user.name },
    image2:state => { return state.user.image2},
    image3:state => { return state.user.image3},
    dark: state => { return state.dark },

  },
  modules: {
  }
})