import '@babel/polyfill'
import 'mutationobserver-shim'
import Raphael from 'raphael/raphael'
global.Raphael = Raphael
import Vue from 'vue'
import './plugins'
import App from './App.vue'
import router from './router'
import store from './store'
import './directives'
import 'leaflet/dist/leaflet.css';
import DateFilter from "./filters/date";
import DateIsoFilter from "./filters/isoDate";
// @ is an alias to /src
import axios from "axios";

const API_BASE_URL = process.env.VUE_APP_API_URL;

Vue.use({
  install(Vue) {
      Vue.prototype.$api = axios.create({
          baseURL: API_BASE_URL,
          withCredentials: true,
      });
      /*
       console.warn( window.location.href + "   <-- window.location.href ");         //  http://localhost:8080/show-conv-login?ayuda_User_ticket=57&amp;expires...
      console.warn( window.location.pathname + "  <-- window.location.pathname ");  //  show-conv-login 
      console.warn(  router.currentRoute.path + "   <-- router.currentRoute.path ");    // /                                                                         
      console.warn(window.location.search.substr(0)+"   <-- substr " ); // ?ayuda_User_ticket=57&amp%3Bexpires...
       */
      let url = process.env.VUE_APP_API_URL;  //http://localhost:8000
      const routeParams  = window.location.search.substr(0);
      const currentRoute  = window.location.pathname;
      if (currentRoute === "/comoingreso") {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
              window.location = `${process.env.VUE_APP_APIEXTERNA_URL}/login?origin=nivelando`
          });
        }

      if (currentRoute === "/nopermitido") {
          Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
              window.location = `${process.env.VUE_APP_API_URL}/nopermitido`
          });
      }
      if (currentRoute === "/show-conv-login") {
            Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
             window.location.href = url + "/api" +currentRoute+routeParams;
             });
        }
      Vue.prototype.$api.interceptors.response.use(
          function (response) {
              if (currentRoute === "/show-conv-login") {
                  Vue.prototype.$api.get("/sanctum/csrf-cookie").then( () => {
                  });
              }
              return response;
          },
          function (error) {
              if (error.response.status === 401)
              {
                  const currentRoute  = window.location.pathname;
                  let url = process.env.VUE_APP_API_URL;
                  if (currentRoute === "/show-conv-login") {
                      Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                          window.location.href = url + "/api" + window.location.href;
                      });
                  }
                  else {
                      //console.log("entrando normal aqui")
                      Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                          window.location.href =  url + "/redirect";
                      });
                  }
              }
              if (error.response.status === 419) {
                  Vue.prototype.$api.get("/sanctum/csrf-cookie").then(() => {
                  });
              }
              return Promise.reject(error);
          }
      );
  },
});
Vue.config.productionTip = false;
Vue.filter("date", DateFilter);
Vue.filter("isoDate", DateIsoFilter);
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')