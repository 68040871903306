<template>
  <b-container fluid>
    <b-row>
      <div class="col-lg-12">
        <div class="card card-block">
          <div class="card-header  text-center">
            <b-jumbotron lead="Cambio de contraseña exitoso" class="text-center"></b-jumbotron>
            <p class="card-body">
              La información de restablecimiento de contraseña fue enviada a tu correo institucional: <span class="text-primary">{{this.$store.getters.usuario.correo_institucional}}</span>
            </p>
            <p class="card-body">
              Correo: <span class="text-primary">{{email}}</span>
            </p>
            <p class="card-body">
              Nueva contraseña: <span class="text-primary">{{pwd}}</span>
            </p>
          </div>
        </div>
      </div>
    </b-row>

  </b-container>
</template>
<script>
export default {
  name: "ResetEmail",
data(){
    return{
      email:'',
      pwd:''
    }
},
  mounted() {
    this.email = this.$route.params.email;
    this.pwd   = this.$route.params.password;

  },
  methods: {
    gotoNivelando(){
      window.open('https://nivelando.estudiolab.app', '_blank');
    }
  },
};
</script>
