/* eslint-disable vue/no-unused-vars */
<template>
  <b-container>
               
    <b-row>
        <b-alert
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
            class="alert-fixed text-center text-bold"> 
           <p> <span>{{textoSnackbar}}</span></p>
        </b-alert>
      <b-col cols="12">
        <div class="card card-block">
          <div class="card-body p-2">
                <div class="d-flex justify-content-between align-items-center p-2">
                  <h5 class="font-weight-bold">Tickets de soporte</h5>
               </div>
                <div class="d-flex justify-content-end align-items-center p-2 my-2">
                    <b-button  variant="outline-secondary" size="md"  @click="verManual()">Manual</b-button>
               </div>
              <b-tabs content-class="mt-3" justified>
                <b-tab title="Tickets abiertos" active>
                                <div class="d-flex justify-content-between row p-2">
                                    <div class="col-sm-12 col-md-6 d-flex align-items-center">                                      
                                        <svg  @click="getData()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                        </svg>                                       
                                    </div>
                                    <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                        <div>
                                            <b-form-input
                                            id="filter-input"
                                            v-model="filter"
                                            type="search"
                                            placeholder="Buscar"
                                            ></b-form-input>
                                        </div>
                                    </div>
                                </div>

                   <b-table class="table data-table"
                    :items="resultSet"
                    :fields="fields"
                    :current-page="currentPage"
                    :per-page="perPage"
                    :filter="filter"
                    :filter-included-fields="filterOn"
                        stacked="md"
                        show-empty
                        small
                       >
                        <template #empty="scope">
                            <h4 class="text-center">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</h4>
                        </template>                       
                    <template #cell(id)="row">   
                        <div class="text-center">
                            <router-link :to="`/tickets-get/${row.item.id}`" v-b-tooltip.top title="Ver">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </router-link>
                        </div>               
                    </template>
                    </b-table>
                        <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                    <b-pagination
                                    v-model="currentPage"
                                    :total-rows="totalRows"
                                    :per-page="perPage"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    ></b-pagination>
                                    </div>
                                </div>
                        </div>
                 </b-tab>

                <b-tab  @click="getMyTickets()" title="Mis tickets">
                     <div class="d-flex justify-content-between row p-2">
                        <div class="col-sm-12 col-md-6 d-flex align-items-center">
                            <svg  @click="getMyTickets()" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                            <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                            <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                            </svg> 
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-form-input
                                id="filter-input"
                                v-model="filter2"
                                type="search"
                                placeholder="Buscar"
                                ></b-form-input>
                            </div>
                        </div>
                    </div>
                <b-table class="table data-table"
                    :items="resultSet2"
                    :fields="fields"
                    :current-page="currentPage2"
                    :per-page="perPage2"
                    :filter="filter2"
                    :filter-included-fields="filterOn2"
                        stacked="md"
                        show-empty
                        small
                       >
                        <template #empty="scope">
                            <h4 class="text-center">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</h4>
                        </template>
                    <template #cell(id)="row">   
                        <div class="text-center">
                            <router-link :to="`/tickets-get/${row.item.id}`" v-b-tooltip.top title="Ver">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>

                            </router-link>
                        </div>      
                        
                      
                    </template>
                    </b-table>
                        <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center">                            

                                </div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                    <b-pagination
                                    v-model="currentPage2"
                                    :total-rows="totalRows2"
                                    :per-page="perPage2"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    ></b-pagination>
                                    </div>
                                </div>
                        </div>
                </b-tab>
                <b-tab key="tres" @click="getMyClosedTickets()" title="Concluidos">

                     <div class="d-flex justify-content-between row p-2">
                        <div class="col-sm-12 col-md-6 d-flex align-items-center">
                                    <svg  @click="getMyClosedTickets" xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="currentColor" class="bi bi-arrow-repeat reload mx-2" viewBox="0 0 16 16">
                                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z"/>
                                    <path fill-rule="evenodd" d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z"/>
                                    </svg>                             
                        </div>
                        <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                            <div>
                                <b-form-input
                                id="filter-input"
                                v-model="filter3"
                                type="search"
                                placeholder="Buscar"
                                ></b-form-input>
                            </div>
                        </div>
                    </div>

                <b-table class="table data-table"
                    :items="resultSet3"
                    :fields="fields"
                    :current-page="currentPage3"
                    :per-page="perPage3"
                    :filter="filter3"
                    :filter-included-fields="filterOn3"
                        stacked="md"
                        show-empty
                        small
                       >
                        <template #empty="scope">
                            <h4 class="text-center">{{ scope.emptyText='Por el momento no hay registros que mostrar' }}</h4>
                        </template>
                    <template #cell(id)="row">   
                        <div class="text-center">
                            <router-link :to="`/tickets-get/${row.item.id}`" v-b-tooltip.top title="Ver">
                                <svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" fill="currentColor" class="bi bi-chevron-right" viewBox="0 0 16 16">
                                <path fill-rule="evenodd" d="M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z"/>
                                </svg>
                            </router-link>
                        </div>      
                    </template>
                    </b-table>
                        <div class="d-flex justify-content-between row p-2">
                                <div class="col-sm-12 col-md-6 d-flex align-items-center"></div>
                                <div class="col-sm-12 col-md-6 d-flex align-items-center justify-content-end">
                                <div>
                                    <b-pagination
                                    v-model="currentPage3"
                                    :total-rows="totalRows3"
                                    :per-page="perPage3"
                                    align="fill"
                                    size="sm"
                                    class="my-0"
                                    ></b-pagination>
                                    </div>
                                </div>
                        </div>
                </b-tab>
                
              </b-tabs>
              
          </div>

        </div>


        </b-col><!-- main center-->
     
      </b-row>

       
  </b-container>
</template>

<script>
export default {
    data(){
      return{

        //alert confirm dismissble
          textoSnackbar:'',
          dismissSecs: 3,
          dismissCountDown: 0,
          showDismissibleAlert: false,
        //alert confirm dismissble
                
            fields: [
            {
                label: 'Correo',
                class: 'text-start',
                key: 'email_user_solicitante',
            },
            { label: 'Mensaje', key: 'mensaje', class: 'text-start' },
            { label: 'Acciones', key: 'id', class: 'text-center' },
            
            ],


            totalRows: 1,
            currentPage: 1,
            perPage: 5,
            pageOptions: [5, 10, 15, { value: 100, text: "Show a lot" }],
            filter: null,
            filterOn: [],
            resultSet:[],
            search: '',
           

            totalRows2: 1,
            currentPage2: 1,
            perPage2: 5,
            pageOptions2: [5, 10, 15, { value: 100, text: "Show a lot" }],
            filter2: null,
            filterOn2: [],
            resultSet2:[],
            search2: '',

            totalRows3: 1,
            currentPage3: 1,
            perPage3: 5,
            pageOptions3: [5, 10, 15, { value: 100, text: "Show a lot" }],
            filter3: null,
            filterOn3: [],
            resultSet3:[],
            search3: '',

      }
    },
     methods: {
    async getData(){
            this.resultSet = []; 
            const url                       =   `/api/all-tickets`;   
            const response                  =   await this.$api.get(url);
            let {message, data, resultSet}  =   response.data;
            this.resultSet                  =   (data == 1) ? resultSet : [];
            this.totalRows                  =   this.resultSet.length;
            this.textoSnackbar              =   message;
            this.showAlert();
        },//getData

    async getMyTickets(){   
            this.resultSet2 = [];     
            const url                       = `/api/all-myTickets`;    
            const response                  = await this.$api.get(url);
            let {message, data, resultSet}  = response.data;
             this.resultSet2                = (data == 1) ? resultSet : [];
             this.totalRows2                = this.resultSet2.length;
             this.textoSnackbar             =   message;
             this.showAlert();                      
        },//getMyTickets

    async getMyClosedTickets(){
            this.resultSet3 = [];   
            const url                       =   `/api/all-myClosedTickets`;  
            const response                  =   await this.$api.get(url);
            let {message, data, resultSet}  =   response.data;
            this.textoSnackbar              =   message;
            this.resultSet3                 = (data == 1) ? resultSet : [];
            this.totalRows3                 = this.resultSet3.length;
            this.showAlert();
        },//getMyClosedTickets

   async verManual(){
       await this.$router.push(`/manual`);
    },
    countDownChanged(dismissCountDown) {
        this.dismissCountDown = dismissCountDown
      },
    showAlert() {
        this.dismissCountDown = this.dismissSecs
      }
    },
    mounted () {
    this.getData();
    },

}
</script>

<style>
.reload {
  fill: #B2007B !important;
}

.page-item.active .page-link {
    background-color: #B2007B !important;
    border-color: #B2007B !important;
    color: white !important;
    }

    .page-link {
        border-color: #B2007B !important;
        color:#B2007B !important;
    }
    
.alert-fixed {
    position:fixed;
    bottom: 2%; 
    left: 30%;
    z-index:9999; 
    border-radius:5px;
}
    
</style>