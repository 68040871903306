/* eslint-disable vue/no-unused-vars */
<template>
   <v-container>


         <v-row align="center" justify="center">

            <v-card elevation="5" style="padding:50px">
                <v-card-title class="headline">
                Solicitud
                </v-card-title>
                
                <v-card-text>
                   
                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                <br>
                                <v-alert
                                    dense
                                    type="info"
                                    prominent
                                    >
                                    A continuación, se enlistan las solicitudes de AYUDA que usted ha dado de alta, al hacer clic en el botón <b>VER SOLICITUD </b>, se muestran los detalles de la solicitud así como los datos de la cuenta en cuestión. <br>
                                   Si se rechaza la solicitud, habrá que especificar el motivo de rechazo...
                                    
                                     <br>
                                </v-alert>

                            </v-col>
                        </v-row>

                        <v-row>
                            <v-col cols="12" md="12" sm="12">
                                    <v-card>
                                        <v-card-title>
                                        <v-text-field
                                            v-model="search"
                                            append-icon="mdi-magnify"
                                            label="Buscar"
                                            single-line
                                            hide-details
                                        ></v-text-field>
                                        </v-card-title>
                                       
                             <v-snackbar
                                    v-model="snackbar"
                                     color="success"
                                     :timeout="timeout"
                                    >
                                   {{textoSnackbar}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>

                                <v-snackbar
                                    v-model="snackbar2"
                                    color="success"
                                    :timeout="timeout"
                                    >
                                {{textoSnackbar2}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar2 = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>
                                    
                                    <v-snackbar
                                    v-model="snackbar3"
                                    color="red"
                                    :timeout="timeout"
                                    >
                                {{textoSnackbar3}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar3 = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>

                                    <v-snackbar      
                                    v-model="snackbar4"
                                    color="red"
                                    :timeout="timeout"
                                    >
                                        {{textoSnackbar4}}
                                    <template v-slot:action="{ attrs }">
                                        <v-btn
                                        color="white"
                                        text
                                        v-bind="attrs"
                                        @click="snackbar4 = false"
                                        >
                                        Cerrar
                                        </v-btn>
                                    </template>
                                    </v-snackbar>

                                        <v-data-table
                                        :headers="headers"
                                        :items="resultSet"
                                        :items-per-page="7"
                                        :search="search"
                                         loading-text="Cargando..."
                                        >
                                        
                                         // eslint-disable-next-line vue/no-unused-vars
                                         <template v-slot:item.id="{ item }">

                                                    <v-btn style="color: #fff"
                                                    elevation="4"
                                                    color="purple"
                                                    @click="abrirDialogSolicitud(item.id)"
                                                    >Ver Solicitud
                                                    </v-btn>
                                        </template>
                                        
                                        </v-data-table>
                                    </v-card>

                            </v-col>
                        </v-row>
                        <v-dialog
                        v-model="dialog2"
                        persistent
                        max-width="1400"
                        >
                        <v-card>
                            <v-card-title class="headline">
                            Información de la solicitud
                            </v-card-title>
                            <v-card-text>
                               
                                <v-row>
                                <v-col cols="6">
                                <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                          <b>  Datos de solicitud</b>
                                        </v-card-title>
                                        <v-card-text>
                                            <h3 class="py-1"> Nombres <i>{{this.nombres}} {{this.ApellidoPaterno}} {{this.apellidoMaterno}}</i></h3>
                                            <h3 class="py-1"> RUT <i>{{this.RUT}}</i></h3>
                                            <h3 class="py-1">Sostenedor : <i>{{this.sostenedor}}</i></h3>
                                            <h3 class="py-1">Dirección Sostenedor : <i>{{this.direccionSostenedor}}</i> </h3>
                                            <h3 class="py-1">dependencia : <i>{{this.dependencia}}</i> </h3>
                                            <h3 class="py-1">Cargo principal :<i>{{this.direccionSostenedor}}</i></h3>
                                           <a :href=this.comprobanteDocumental download>
                                               <b> Documento adjunto</b>
                                            </a>
                                        </v-card-text>
                                </v-card>                           
                             </v-col> 
                             <v-col cols="6">
                             
                              <v-card elevation="3" style="padding:15px">
                                        <v-card-title class="headline">
                                           <b> Datos de cuenta</b>
                                        </v-card-title>
                                        <v-card-text>
                                            <h3 class="py-1">Nombre <i>{{this.dominio}}</i></h3>
                                            <h3 class="py-1">Nombre cuenta workspace <i>{{this.cuentaNombreWorkspace}}</i></h3>
                                            <h3 class="py-1">Domicilio cuenta workspace </h3>
                                            <br>
                                            <i>{{this.cuentaDomicilioWorkspace}}</i>
                                            <br>
                                            <h3 class="py-1">Fecha creación cuenta <i>{{this.cuentaFechaCreacion}}</i></h3>
                                            <br>
                                        </v-card-text>
                                </v-card> 
                             
                            <v-row class="mt-4" style="text-align:center">
                                
                                <v-col cols="6">
                                    <v-btn 
                                    elevation="4"
                                    large
                                     @click="aprobarSolicitud()"
                                    >Aprobar</v-btn>
                                </v-col>
                                 <v-col cols="6">
                                    <v-btn
                                    elevation="4"
                                    large
                                        @click="abrirDialogMotivoRechazo()"
                                    >Rechazar
                                    </v-btn>
                                                        <v-dialog
                                                            v-model="dialog"
                                                            persistent
                                                            max-width="600"
                                                            >
                                                            <v-card>
                                                                <v-card-title class="headline">
                                                              Motivo rechazo
                                                                </v-card-title>
                                                                <v-card-text>
                                                                <v-radio-group v-model="motivo">
                                                                    <v-radio
                                                                        label="Cuenta no personal"
                                                                        color="red"
                                                                        value="1"
                                                                    ></v-radio>

                                                                    <v-radio
                                                                        label="Información no coincide"
                                                                        color="red"
                                                                        value="2"
                                                                    ></v-radio>

                                                                    <v-radio
                                                                        label="Solicitud incompleta"
                                                                        color="red"
                                                                        value="3"
                                                                    ></v-radio>
                                                                    </v-radio-group>
                                                                 </v-card-text>
                                                                <v-card-actions>
                                                                  <v-btn 
                                                                    color="green"
                                                                    text
                                                                    @click="guardarMotivoRechazo()"
                                                                >
                                                                    Guardar
                                                                </v-btn>
                                                                <v-spacer></v-spacer>
                                                                <v-btn
                                                                    color="green darken-1"
                                                                    text
                                                                    @click="dialog = false"
                                                                >
                                                                    Volver
                                                                </v-btn>
                                                                </v-card-actions>
                                                            </v-card>
                                                            </v-dialog>
                                 </v-col>
                            </v-row>

                             </v-col>
                            </v-row>                                    
                                    </v-card-text>
                                <v-card-actions>
                                <v-spacer></v-spacer>
                                <v-btn
                                    color="green darken-1"
                                    text
                                    @click="dialog2 = false"
                                >
                                    Cancelar
                                </v-btn>
                                </v-card-actions>
                            </v-card>
                            </v-dialog>
                        
                  
                </v-card-text>
            </v-card>
            
        </v-row>
  </v-container>
</template>

<script>
export default {
data() {
    return {
        ApellidoPaterno:null,
        apellidoMaterno:null,
        nombres:null,
        
        RUT:null,
        sostenedor:null,
        direccionSostenedor:null,
        RUTSostenedor:null,
        
        dependencia:null,
        cargoPrincipal:null,
       
        dominio:null,
        comprobanteDocumental:null,
        tipo:null,

        cuentaNombreWorkspace:null,
        cuentaDomicilioWorkspace:null,
        cuentaFechaCreacion:null,

        timeout:2000,
        snackbar:false,
        textoSnackbar:"Solicitud aprobada con éxito",
        snackbar2:false,
        textoSnackbar2:"Solicitud rechazada exitosamente",
        snackbar3:false,
        textoSnackbar3:"¡Se debe elegir una opción!",
        snackbar4:false,
        textoSnackbar4:"¡Ha ocurrido un error! Favor de intentarlo más adelante o comunicarse con el equipo de desarrollo",
        texto1:'',
        texto2:'',
        texto3:'',
        texto4:'',
        selected_id:'',
        resultSet:[],
        item:[],
        dialog2:false,
       dialog: false,
       motivo:null,
       search: '',
          headers: [
          {
            text: 'Sostenedor',
            align: 'start',
            value: 'sostenedor',
          },
          { text: 'Dominio', value: 'cuentaNombre' },
          { text: 'Fecha Solicitud', value: 'fecha' },
          { text: 'Acciones',   value: 'id',  align:'center'},
        ]
    }
},
    mounted(){
         this.getData();
      //   this.getParametrizacion();
    },
methods: {
    aprobarSolicitud(){
       const url = `/api/aprobar-solicitud`;
          this.$api.post(url,  {
                    id: this.selected_id
                    })
          .then(() => {
              this.getData();
              this.motivo=null;
              this.snackbar = true;
          })
          .catch(() => {
            this.snackbar4 = true;
          })
            this.selected_id=null;
            this.dialog = false;
            this.dialog2 = false;
    },
    abrirDialogSolicitud(item){

    const url = `/api/get-solicitud/${item}`;
    this.$api.get(url)
        .then((response) => {
                
               // console.log(response.data);
               this.ApellidoPaterno         = response.data.apellidoPaterno;
               this.apellidoMaterno         = response.data.apellidoMaterno;
               this.nombres                 = response.data.nombres;
       
                this.RUT                    = response.data.RUT;
                this.sostenedor             = response.data.sostenedor;
                this.direccionSostenedor    = response.data.direccionSostenedor;

                this.RUTSostenedor          = response.data.RUTSostenedor;

                if(response.data.dependencia == 1) {  this.dependencia = "Municipal DAEM"; }
                if(response.data.dependencia == 2) {  this.dependencia = "Municipal Corporación"; }
                if(response.data.dependencia == 3) {  this.dependencia = "Particular Subvencionado"; }
                if(response.data.dependencia == 4) {  this.dependencia = "Particular no Subvencionado"; }
                if(response.data.dependencia == 5) {  this.dependencia = "Administración delegada"; }
                if(response.data.dependencia == 6) {  this.dependencia = "Servicio local de educación"; }
              
                this.cargoPrincipal         = response.data.cargoPrincipal;

                this.dominio                = response.data.cuentaNombre;
                this.comprobanteDocumental  =response.data.comprobanteDocumental;
                this.tipo                   = response.data.tipo;

                this.cuentaNombreWorkspace      = response.data.cuentaNombreWorkspace;
                this.cuentaDomicilioWorkspace   =response.data.cuentaDomicilioWorkspace;
                this.cuentaFechaCreacion        =response.data.cuentaFechaCreacion;

        })


        this.selected_id=item;
        this.dialog2 = true;
    },
    abrirDialogMotivoRechazo(){
        this.dialog = true;
    },
        getData(){
                  this.loading = true;
                  this.$api.get("/api/get-solicitudes")
                    .then((response) => {
                    this.resultSet = response.data;
                    })
                    this.loading = false;
        },
        guardarMotivoRechazo(){
        this.snackbar2 = true;

if(!this.motivo){ 
    this.snackbar3 = true;
    return false;}

        const url = `/api/rechazo-solicitud`;
          this.$api.post(url,  {
                    id: this.selected_id,
                    motivo: this.motivo,
                    })
          .then(() => {
              this.getData();
              this.motivo=null;
              this.snackbar2 = true;

          })
          .catch(() => {
            this.snackbar4 = true;
              
          })
            this.selected_id=null,
            this.dialog = false;
            this.dialog2 = false;
        },

                getParametrizacion(){
                  this.loading = true;
                  this.$api.get("/api/get-parametrizacion")
                    .then((response) => {

                    // this.resultSet = response.data;
                     this.texto1=response.data[0]['texto1'];
                     this.texto2=response.data[0]['texto2'];
                     this.texto3=response.data[0]['texto3'];
                     this.texto4=response.data[0]['texto4'];
                    })
                    this.loading = false;
        }
},
}
</script>

<style scoped>

</style>