/* eslint-disable vue/no-unused-components */
/* eslint-disable vue/valid-v-for */
/* eslint-disable vue/no-unused-vars */
<template>
 <b-container>
     <b-row class="mx-auto text-center">
           <b-alert
            :show="dismissCountDown"
            dismissible
            variant="success"
            @dismissed="dismissCountDown=0"
            @dismiss-count-down="countDownChanged"
            class="alert-fixed text-center text-bold"> 
           <p> <span>{{textoSnackbar}}</span></p>
        </b-alert>
         <b-col cols="12" sm="12" md="12" lg="10" xl="10">
 

            <b-card style="style=padding:50px">
                <b-card-text>               
                    <b-row>
                        <b-col cols="9" md="9" sm="12"></b-col>
                        <b-col cols="3" md="3" sm="12" class="py-5">
                            <b-button  variant="outline-secondary" size="sm"  @click="irAllTickets()">Todos los tickets</b-button>
                        </b-col>
                    </b-row>
                    <b-row>
                         <b-col cols="12">
                            <b-tabs content-class="mt-3" justified>
                                <b-tab title="Básicos" active>
                                  <b-row class="text-left">
                                      <b-col cols="12">
                                           <b-card
                                                title="Detalles básicos de la solicitud de soporte"
                                                class="mb-2">
                                                <b-card-text>
                                                    <span> <b> Nombre del solicitante </b> : <i><u> {{user_name_solicitante}}</u></i> </span>
                                                    <br>
                                                    <span> <b> Correo del solicitante </b> :  <i><u> {{email_user_solicitante}}</u></i> </span>
                                                    <br>
                                                    <span> <b> Fecha creación </b> :  <i><u> {{created_at}}</u></i> </span>
                                                    <br>
                                                    <span> <b> Mensaje </b> :  </span>
                                                    <p style="padding:15px; border: 1px solid #dedede">
                                                        <i> {{mensaje_ticket}}</i>
                                                    </p>
                                                        <div v-if="this.url_adjunto!='#'">
                                                        <a target="_blank"
                                                            :href=this.url_adjunto>
                                                            <b> Documento adjunto</b>
                                                        </a>
                                                        </div>
                                                    <br><br>                             
                                                </b-card-text>
                                                <b-button   v-if="estatus_ticket==1"
                                                            @click="showAtender=true" 
                                                            size="sm" 
                                                            variant="outline-secondary">Atender
                                                </b-button>                                       
                                            </b-card>
                                      </b-col>
                                  </b-row>
                                  

                                </b-tab><!--Básicos-->
                                <b-tab title="Técnicos">
                                   
                                     <b-row class="text-left">
                                      <b-col cols="12">
                                           <b-card
                                                title="Detalles técnicos de la solicitud de soporte"
                                                class="mb-2">
                                                <b-card-text>
                                                    <span> <b> Proyecto </b> : <i><u> {{proyecto_nombre}}</u></i> </span>
                                                    <br>
                                                        <span> <b> Sección </b> : <i><u> {{seccion_modulo_nombre}}  <small>({{seccion_modulo_url}})</small></u></i> </span><br>
                                                        <span> <b> Url </b> : <i><u> {{url_actual}}</u></i> </span><br>
                                                        <span> <b> Plataforma </b> : <i><u> {{platform}}</u></i> </span><br>
                                                        <span> <b> Navegador </b> : <i><u> {{browser}}</u></i> </span>          
                                                        <br>
                                                        <hr>
                                                        <span> <b> Patrón del navegador </b> : <small><i><u> {{browser_name_pattern}}</u></i></small> </span><br>
                                                        <span> <b> Parent </b> : <small> <i><u> {{parent}}</u></i></small> </span>            <br>               
                                                </b-card-text>

                                               
                                            </b-card>
                                      </b-col>
                                  </b-row>



                                </b-tab><!--Técnicos-->
                                
                                <b-tab title="Seguimiento" @click="showConversation" v-if="estatus_ticket!=1" class="text-left">
                                    <h5 class="my-4">
                                        Mensajes y seguimiento de la solicitud de soporte
                                    </h5>

                                    <div class="d-flex justify-content-between my-3">           
                                        <b-button
                                                 v-if="estatus_ticket!=1 && estatus_ticket!=3 && estatus_ticket!=4 && estatus_ticket!=5"
                                                 @click="showEnviarMensajeSeguimiento=true" 
                                                 size="sm" 
                                                 variant="outline-secondary"
                                                 >
                                                 Enviar un mensaje al solicitante
                                        </b-button>
                                                                
                                        <b-button 
                                                v-if="estatus_ticket!=3 && estatus_ticket!=4 && estatus_ticket!=5" 
                                                @click="show=true" 
                                                size="sm" 
                                                variant="outline-secondary">
                                                Cerrar ticket
                                        </b-button>
                                    </div>


                                    <b-modal
                                    size="lg"
                                    v-model="show"
                                    title="Cerrar ticket"
                                    >
                                    <b-container fluid>
                                        <b-row class="m-1 text-left">
                                        <b-col cols="12">
                                            <b-form-group label="Especificar el motivo de cierre de la solicitud">
                                                <b-form-radio v-model="motivo"  name="some-radios" value="3">CONCLUIDO POR PETICION DEL CLIENTE</b-form-radio>
                                                <b-form-radio v-model="motivo"  name="some-radios" value="4">CONCLUIDO POR FALTA DE RESPUESTA POR PARTE DEL CLIENTE</b-form-radio>
                                                <b-form-radio v-model="motivo" name="some-radios" value="5">CONCLUIDO SATISFACTORIAMENTE</b-form-radio>
                                            </b-form-group>
                                        </b-col>   
                                        </b-row>
                                    </b-container>
                                    <template #modal-footer>
                                        <div class="w-100">
                                        <b-button  
                                            variant="primary" 
                                            size="sm" 
                                            @click="guardarMotivoCierre()"
                                            class="float-right mx-2">

                                            Guardar
                                        </b-button>
                                        
                                        <b-button
                                            variant="primary"
                                            size="sm"
                                            class="float-right"
                                            @click="show=false"
                                        >
                                            Cancelar
                                        </b-button>
                                        </div>
                                    </template>
                                    </b-modal>

                                    <!--main span - show conversation-->
                                    <span
                                        v-for="(result, key ) in resultSet"
                                        :key="key">
                                            
                                            <!--v-alert-->
                                            <div v-if="result['RespuestaSeguimientoTicket']" class="p-2 respuesta_user mt-3 text-right">
                                                <span show variant="light" v-if="result['mensajes']">      

                                                    <span v-for="(mensaje, index ) in result['mensajes']" :key="index">
                                                        <span v-html="mensaje"></span>  - <span class="caption"> <i style="color:#95a5a6">{{result['fechasRes'][index]}} </i></span>                                                     
                                                        <div v-if=" result['adjuntosRes'][index]!='#'">
                                                            <a target="_blank"
                                                                :href= "result['adjuntosRes'][index]">
                                                                <hr class="my-4">
                                                                    <b-img
                                                                        fluid 
                                                                        v-bind  ="imageMainProps"
                                                                        :src    ="result['adjuntosRes'][index]"
                                                                    ></b-img>
                                                            </a>
                                                        </div>
                                                        <hr class="my-4">

                                                    </span>
                                                </span>   
                                            </div>          

                                            <!--v-alert-->
                                            <div class="text-left p-2 respuesta_soporte mt-3">
                                                                    
                                                    <!--   respuesta soporte -->
                                                    <p class="text-left" show variant="light">
                                                        <span class="caption" >  <i style="color:#95a5a6"> {{result['created_at']}} </i></span>  - <span v-html="result['SeguimientoTicket']"></span> 
                                                    </p>   
                                                     <hr class="my-4">
                                                        <div v-if=" result['adjunto_seguimiento']!='#'">
                                                            <a target="_blank"
                                                                :href= "result['adjunto_seguimiento']">
                                                                    <b-img
                                                                        fluid
                                                                        v-bind  ="imageMainProps"
                                                                        :src        ="result['adjunto_seguimiento']"
                                                                    ></b-img>
                                                            </a>
                                                        </div>
                                                
                                            </div>    
                                    </span>
                                     <!--main span - show conversation-->
                                     
                                            <div class="text-right p-2 respuesta_user mt-3 fondo_msjOriginal">
                                                <span show variant="light">  
                                                                   
                                                <span v-html="original_Ticket_text"></span> <span class="caption"> <i style="color:#95a5a6"> -{{created_at}} </i></span>
                                                 <hr class="my-4">
                                                   <div v-if="this.original_ticket_adjunto!='#'">
                                                        <a target="_blank"
                                                            :href=this.original_ticket_adjunto>
                                                                <b-img
                                                                    fluid
                                                                    v-bind="imageMainProps"
                                                                    :src        ="original_ticket_adjunto"
                                                                ></b-img>
                                                        </a>
                                                    </div>
                                                </span>   
                                            </div>                                  
                                    
                                    

                                </b-tab><!--Seguimiento-->
                            </b-tabs>
                         </b-col>
                    </b-row>
                </b-card-text>
            </b-card>

         </b-col>
         





     </b-row>


 <b-modal
      size="lg"
      v-model="showAtender"
      title=" Mensaje a enviar al solicitante"
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">     
            <b-form-textarea
                id="textarea"
                v-model="mensajeSoporteTecnico"
                placeholder="Manda un mensaje al solicitante"
                rows="3"
                max-rows="6"
                ></b-form-textarea>
          </b-col>   
        </b-row>
      </b-container>

      <template #modal-footer>
        <div class="w-100">

          <b-button  
            @click="atender()"
            variant="primary" 
            size="sm" 
            class="float-right mx-2">
             Asignarme Ticket
          </b-button>
          
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showAtender=false"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

 <b-modal
      size="lg"
      v-model="showEnviarMensajeSeguimiento"
      title="Mensaje a manera de seguimiento"
    >
      <b-container fluid>
        <b-row class="m-1 text-left">
          <b-col cols="12">     

                
                <vue-editor v-model="mensajeSeguimiento" 
                        :editorToolbar="customToolbar" 
                        :placeholder="placeholder"
                        ></vue-editor>
    
    <b-form-file class="my-3"
        size="sm"
        v-model="imagen"
        :state="Boolean(imagen)"
        placeholder="Archivo adjunto..."
        drop-placeholder="Arrastrar un archivo para adjuntar."
    ></b-form-file>

          </b-col>   
        </b-row>
      </b-container>
      <template #modal-footer>
        <div class="w-100">

          <b-button  
            @click="enviarMensaje()"
            variant="primary" 
            size="sm" 
            class="float-right mx-2">
             Enviar mensaje
          </b-button>
          
          <b-button
            variant="primary"
            size="sm"
            class="float-right"
            @click="showEnviarMensajeSeguimiento=false"
          >
            Cancelar
          </b-button>
        </div>
      </template>
    </b-modal>

 </b-container>
</template>

<script>
import { VueEditor } from "vue2-editor";
export default {
          components: {
            VueEditor
        },
    data() {
        return {  
          imageMainProps: {  width: 250, height: 150, class: 'text-right' },
          imageMainPropsLeft: { blank: true, width: 250, height: 150, class: 'text-left' },
        //alert confirm dismissble
          textoSnackbar:'',
          dismissSecs: 3,
          dismissCountDown: 0,
          showDismissibleAlert: false,
        //alert confirm dismissble

            showAtender:false,
            showEnviarMensajeSeguimiento:false,
            show: false,
            selected: '',//radio motivo cierre

            mensajeSoporteTecnico: '',
            mensajeSeguimiento:"",
            imagen : [],

            
            original_Ticket_text:'',
            original_ticket_adjunto:'',
            created_at:'',
            mensaje_ticket:"",
            proyecto_nombre:"",
            seccion_modulo_nombre:"",

            seccion_modulo_url:"",
            url_actual:"",
            email_user_solicitante:"",

            dominio_solicitante:"",
            browser_name_pattern:"",
            browser:"",

            parent:"",
            platform:"",
            url_adjunto:"",
            user_name_solicitante:"",

            estatus_ticket: "",
            resultSet:[],
            motivo:null,

            //vue2editor
            content: "",
            placeholder:"Escriba aquí el mensaje al usuario",
            customToolbar: 
            [
                [{ header: [false, 1, 2, 3, 4, 5, 6] }],
                ["bold", "italic", "underline", "strike"],
                [{ color: [] }],
                [{ list: "ordered" }, { list: "bullet" }, { list: "check" }],
                [
                    { align: "" },
                    { align: "center" },
                    { align: "right" },
                    { align: "justify" }
                ],
                [{ indent: "-1" }, { indent: "+1" }], // outdent/indent
                ["link", "video"], // ["link", "image", "video"],
                ["clean"]
            ],
    }

    },//data
    mounted () {
         this.getData();
    },
    methods: {
           async guardarMotivoCierre(){
                    const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
                    const response  =  await this.$api.post(`/api/cerrar-ticket`,  {
                                            id: id,
                                            motivo: this.motivo,
                                        });
                    let {message}           = response.data;
                    this.textoSnackbar      =   message;
                    this.showAlert();
                    this.show=false;

                    setTimeout( () => this.$router.push({ path: '/tickets-all'}), 2500);
                },
        
        countDownChanged(dismissCountDown) {
            this.dismissCountDown = dismissCountDown
        },
        showAlert() {
            this.dismissCountDown = this.dismissSecs
        },
        irAllTickets(){
        this.$router.push(`/tickets-all`);
        },

        async getData(){
            const id                        = (this.$route.params.id) ? (this.$route.params.id) : 0;
            const url                       = `/api/get-ticket/${id}`;
            const response                  =  await this.$api.get(url);
            let { 
                      message , 
                      mensaje_ticket 
                    , created_at
                    , proyecto_nombre 
                    , seccion_modulo_nombre
                    , seccion_modulo_url
                    , url_actual
                    , email_user_solicitante
                    , dominio_solicitante
                    , browser_name_pattern
                    , browser
                    , parent
                    , platform
                    , url_adjunto
                    , user_name_solicitante
                    , estatus_ticket
                    } = response.data;

                this.mensaje_ticket         = mensaje_ticket;
                this.created_at             = created_at;
                this.proyecto_nombre        = proyecto_nombre;
                this.seccion_modulo_nombre  = seccion_modulo_nombre;
                this.seccion_modulo_url     = seccion_modulo_url;
                this.url_actual             = url_actual;
                this.email_user_solicitante = email_user_solicitante;
                this.dominio_solicitante    = dominio_solicitante;
                this.browser_name_pattern   = browser_name_pattern;
                this.browser                = browser;
                this.parent                 = parent;
                this.platform               = platform;
                this.url_adjunto            = url_adjunto;
                this.user_name_solicitante  = user_name_solicitante;
                this.estatus_ticket         = estatus_ticket;

                this.textoSnackbar              =   message;
                this.showAlert();
        },  //getData
        async atender(){
        const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;

        if(this.mensajeSoporteTecnico == '' || this.mensajeSoporteTecnico == ' ' || this.mensajeSoporteTecnico == null ){
                this.textoSnackbar              =   "El campo mensaje no puede estar vacío";
                this.showAlert();
                return null;
        }
         const url           = `/api/accept-ticket`;
         const response      =  await this.$api.post(url, {
                             id:      id,
                             mensaje: this.mensajeSoporteTecnico
                    });
        let {message} = response.data;
        this.textoSnackbar              =   message;
        this.showAlert();
        this.mensajeSoporteTecnico      = "";
        this.$router.push(`/tickets-all`);
        
    },//atender
    async enviarMensaje(){

        if(this.mensajeSeguimiento == '' || this.mensajeSeguimiento == ' ' || this.mensajeSeguimiento == null ){
             this.textoSnackbar              =   "El mensaje a enviar NO puede estar vacío";
             this.showAlert();
             return null;
        }
        let formData = new FormData();
            formData.append("id", (this.$route.params.id) ? (this.$route.params.id) : 0  );
            formData.append("imagen", this.imagen);
            formData.append("mensaje", this.mensajeSeguimiento);

        const response  = await this.$api.post(`/api/sendMessage-ticket`, formData, 
                      { 
                        headers: {'content-type': 'multipart/form-data'} 
                      }
                    );

        console.log(response.data);

        let {message}            = response.data;
        this.textoSnackbar              =   message;
        this.showAlert();
        this.mensajeSeguimiento         ='';
        this.showEnviarMensajeSeguimiento   = false;   
     //   this.showConversation();
    }//enviarMensaje

    ,showConversation(){

            const id  = (this.$route.params.id) ? (this.$route.params.id) : 0;
            const url = `/api/show-Conversation/${id}`;

            this.$api.get(url)
            .then( (response) => {
             console.log(response.data);
             
             this.resultSet                 = response.data.resultSet;
             this.original_Ticket_text      = response.data.original_Ticket_text;
             this.original_ticket_adjunto   = response.data.original_ticket_adjunto;
             this.created_at                = response.data.created_at;
            
        })
        .catch( (e) => {
            console.log('ERROR ENCONTRADO ' + e);
        });

},



    },//methods
}


</script>

<style scoped>

    .respuesta_user
    {   
        border-right: 3px solid #00bcd4 !important;
        border-left: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
      
    }

    .respuesta_soporte
    {   
        border-left: 3px solid rgb(149, 47, 129) !important;
        border-right: 0px !important;
        border-top: 0px !important;
        border-bottom: 0px !important;
         box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    }

    .fondo_msjOriginal
    {
        background-color:  rgb(241, 242, 246) !important;
    }

    .alert-fixed 
    {
        position:fixed;
        bottom: 2%; 
        left: 30%;
        z-index:9999; 
        border-radius:5px;
    }

</style>